<template>
  <Row type="flex" class="ps-7 pt-1 modal-header">
    <Col
      class="d-flex justify-start align-center mb-4"
      :lg="6"
      :sm="12"
      :span="24"
    >
      <img :src="content.coinDto.logoUrl" width="32" height="32" />
      <div class="ms-2">
        <p class="color-white font-Regular-18 text-start line-height-24">
          {{ content.coinDto.displayName }}
        </p>
        <p class="color-gray text-start font-Regular-12 text-start line-height-20">
          {{ content.description }}
          <!-- {{ content.description ? content.description : content.coinDto.coinUnit }} -->
        </p>
      </div>
    </Col>
    <Col
      class="duration-list mb-4"
      :lg="{ span: 12, order: 2 }"
      :span="24"
      :order="3"
      v-if="content.durations"
    >
      <p class="color-gray font-Regular-14 me-6">
        {{ $t("staking.plan_duration") }}
      </p>
      <div
        :class="[
          'duration-list',
          'd-flex',
          'gap-4',
          content.durations.length > 4 && 'responsive-duration-container',
        ]"
      >
        <div v-for="(duration, index) in content.durations" :key="index">
          <div
            class="d-flex justify-center align-center duration-item"
            :class="
              duration.planDetailId === content.activeDuration && 'active'
            "
            @click="setActiveDuration(duration.planDetailId)"
          >
            <Icon
              size="18"
              type="ios-trending-up"
              v-if="duration.apyType === 0"
            />
            <span class="text-start font-Regular-14 color-gray">{{
              duration.duration
            }}</span>
            <span
              v-if="duration.planDetailId === content.activeDuration"
              class="text-start font-Regular-14 color-gray"
              >{{ $t("staking.day") }}</span
            >
          </div>
        </div>
      </div>
    </Col>
    <Col
      class="d-flex align-center mb-4"
      :lg="{ span: 6, order: 3 }"
      :sm="12"
      :span="24"
      :order="2"
    >
      <p class="color-gray text-center font-Regular-14 me-6">
        {{ $t("staking.plan_status") }}
      </p>
      <div
        v-if="content.status"
        :class="[getStatus(content.status).className, 'plan-status']"
      >
        <span class="font-Regular-12">{{
          getStatus(content.status).text
        }}</span>
      </div>
      <div
        v-else
        :class="[
          'plan-status',
          content.details[0].isRegisterEnded ||
          content.details[0].isRegisterNotStarted ||
          content.details[0].remainCapacity === 0
            ? 'Error'
            : 'Success',
        ]"
      >
        <span class="font-Regular-12">{{
          content.details[0].isRegisterEnded ||
          content.details[0].isRegisterNotStarted ||
          content.details[0].remainCapacity === 0
            ? $t("staking.not_exist")
            : $t("staking.exist")
        }}</span>
      </div>
    </Col>
  </Row>
</template>
<script>
export default {
  props: ["content"],
  methods: {
    setActiveDuration(durationId) {
      this.$emit("updateActiveDuration", durationId);
    },
    getStatus(status) {
      switch (status) {
        case "ACTIVE":
          return { className: "Success", text: this.$t("staking.exist") };
        case "CANCELED":
          return { className: "Error", text: this.$t("staking.status_cancel") };
        case "TERMINATED":
          return {
            className: "Warning",
            text: this.$t("staking.status_terminated"),
          };

        default:
          return {
            className: "Warning",
            text: this.$t("staking.status_terminated"),
          };
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-header {
  .duration-list {
    display: flex;
    align-items: center;
    @media (max-width: 400px) {
      display: block;
      p {
        margin-bottom: 12px;
        text-align: right;
      }
      .duration-list {
        justify-content: center;
      }
    }
    .gap-4 {
      gap: 4%;
      .duration-item {
        border-radius: 4px;
        border: 1px solid #a2aebf;
        padding: 4px 8px 2px 8px;
        width: 64px;
        &.active {
          border: 1px solid #f1ac1c;
          background: #2b3b4f;
          span {
            color: #fff;
          }
        }
      }
    }
    .responsive-duration-container {
      padding: 0 16px;
      padding-bottom: 12px;
      max-width: 320px;
      overflow-x: auto;
      justify-content: flex-start;
    }
    @media (max-width: 510px) {
      .responsive-duration-container {
        padding: 0 16px;
        padding-bottom: 12px;
        max-width: 270px;
        overflow-x: auto;
      }
    }
  }
  .plan-status {
    border-radius: 4px;
    padding: 2px 12px;
    &.Success {
      background: rgba(2, 192, 118, 0.16);
      > span {
        color: #02c076;
      }
    }
    &.Info {
      background: rgba(46, 157, 255, 0.16);
      > span {
        color: #2e9dff;
      }
    }
    &.Warning {
      background: rgba(241, 143, 1, 0.16);
      > span {
        color: #f18f01;
      }
    }
    &.Error {
      background: rgba(248, 73, 96, 0.16);
      > span {
        color: #f84960;
      }
    }
  }
}
</style>
