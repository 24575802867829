<template>
  <div class="relative">
    <div
      class="scroll-container relative z-index-100"
      :class="[isDown && 'active']"
      ref="scrollContainer"
      @mousedown="handleMouseDown"
      @mouseup="handleMouseUp"
      @mousemove="handleMouseMove"
      @mouseleave="handleMouseUp"
      @touchstart="handleTouchStart"
      @touchend="handleTouchEnd"
      @touchmove="handleTouchMove"
    >
      <div class="content" ref="content">
        <div
          v-for="item in navList"
          :key="item.id"
          class="pt-3 pb-3"
          @click="changeTab(item.id)"
        >
          <span
            class="nav-item ps-3 pe-3 text-center"
            :class="selectedTab === item.id && 'active'"
            >{{ item.label }}</span
          >
        </div>
      </div>
    </div>
    <Divider class="absolute mt-0 mb-4 bg-deep-gray nav-divider" />
  </div>
</template>
<script>
export default {
  props: {
    navList: {
      type: Array,
      require: true,
    },
    selectedTab: {
      type: Number,
    },
  },
  data() {
    return {
      isDown: false,
      startX: 0,
      scrollLeft: 0,
    };
  },
  methods: {
    handleMouseDown(event) {
      this.isDown = true;
      this.startX = event.pageX - this.$refs.scrollContainer.offsetLeft;
      this.scrollLeft = this.$refs.scrollContainer.scrollLeft;
    },
    handleMouseUp() {
      this.isDown = false;
    },
    handleMouseMove(event) {
      if (!this.isDown) return;
      const x = event.pageX - this.$refs.scrollContainer.offsetLeft;
      const walk = (x - this.startX) * 3; // Adjust scrolling speed
      this.$refs.scrollContainer.scrollLeft = this.scrollLeft - walk;
    },
    handleTouchStart(event) {
      this.handleMouseDown(event.touches[0]);
    },
    handleTouchEnd() {
      this.handleMouseUp();
    },
    handleTouchMove(event) {
      this.handleMouseMove(event.touches[0]);
    },
    changeTab(id) {
      this.$emit("updateSelectTab", id);
    },
  },
};
</script>
