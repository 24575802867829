<template>
  <div>
    <Row :gutter="30" class="mt-8">
      <Col
          :xl="6"
          :lg="8"
          :md="12"
          :span="24"
          class="mb-8"
          v-for="(plan, index) in plansData"
          :key="index"
      >
        <Card
            :class="[
            'stake-card',
            'public-card',
            listType === 1 && 'registered-card',
          ]"
        >
          <template v-if="listType === 1">
            <template slot="title">
              <Row>
                <Col span="16" class="d-flex justify-center align-center pt-1 pb-1">
                  <img
                      :src="plan.logoUrl"
                      alt=""
                      class="mb-1 me-1"
                      width="48"
                      height="48"
                  />
                  <div>
                    <p
                        class="text-start font-Regular-18 color-white line-height-24"
                    >
                      {{ plan.displayName }}
                    </p>
                    <p
                        class="text-start font-Regular-12 line-height-20 pe-2"
                        style="color: #a2aebf !important;max-width: 110px;"
                    >
                      ({{ plan.planDescription }})
                    </p>
                  </div>
                </Col>
                <Col span="8">
                  <div
                      :class="[
                      'plan-status',
                      getSubscriptionStatus(plan.status).className,
                      'mb-1',
                    ]"
                  >
                    <span class="font-Regular-12">{{
                        getSubscriptionStatus(plan.status).text
                      }}</span>
                  </div>
                  <div
                      class="plan-status Disabled"
                  >
                    {{ plan.duration }} {{ $t("staking.day") }}
                  </div>
                </Col>
              </Row>
            </template>
            <div class="d-flex justify-between align-center mt-2 mb-4">
              <p class="color-gray text-start font-Regular-14">
                {{ $t("staking.earned_reward") }}
              </p>
              <p class="ltr color-green font-Medium-16">
                {{ numberWithCommas(plan.currentReward) }}
                <span class="font-Medium-12">{{ plan.coinUnit }}</span>
              </p>
            </div>
            <div class="d-flex justify-between align-center mb-6">
              <p class="color-gray text-start font-Regular-14">
                {{ $t("staking.auto_renewal") }}
              </p>
              <p class="text-start color-white font-Medium-16">
                {{
                  plan.userApprovedRenewal
                      ? $t("staking.has")
                      : $t("staking.not_has")
                }}
              </p>
            </div>
            <div class="d-flex justify-between align-start">
              <p class="color-gray text-start font-Medium-14 pt-1">
                {{ $t("staking.remained_time") }}
              </p>
              <div class="d-flex" :class="isLocaleLTR ? '':'flex-row-reverse'">
                <div class="ms-3">
                  <p
                      style="
                      width: 32px;
                      height: 32px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      border-radius: 4px;
                      border: 1px solid #394350;
                      background: #141e2c;
                    "
                  >
                    {{ getRemainedDate(plan.endSubscriptionTime).day }}
                  </p>
                  <p
                      style="
                      color: #a2aebf;
                      text-align: center;
                      font-size: 10px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                      margin-top: 2px;
                    "
                  >
                    {{ $t("staking.day") }}
                  </p>
                </div>
                <span
                    class="mt-1 ms-3"
                    style="
                    color: #a2aebf;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                  "
                >:</span
                >
                <div class="ms-3">
                  <p
                      style="
                      width: 32px;
                      height: 32px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      border-radius: 4px;
                      border: 1px solid #394350;
                      background: #141e2c;
                    "
                  >
                    {{ getRemainedDate(plan.endSubscriptionTime).hour }}
                  </p>
                  <p
                      style="
                      color: #a2aebf;
                      text-align: center;
                      font-size: 10px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                      margin-top: 2px;
                    "
                  >
                    {{ $t("staking.hour") }}
                  </p>
                </div>
                <span
                    class="mt-1 ms-3"
                    style="
                    color: #a2aebf;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                  "
                >:</span
                >
                <div :class="isLocaleLTR ? 'ms-3' : ''">
                  <p
                      style="
                      width: 32px;
                      height: 32px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      border-radius: 4px;
                      border: 1px solid #394350;
                      background: #141e2c;
                    "
                  >
                    {{ getRemainedDate(plan.endSubscriptionTime).minute }}
                  </p>
                  <p
                      style="
                      color: #a2aebf;
                      text-align: center;
                      font-size: 10px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: normal;
                      margin-top: 2px;
                    "
                  >
                    {{ $t("staking.minute") }}
                  </p>
                </div>
              </div>
            </div>
            <Button
                class="mt-8 mb-2 detail-btn"
                @click="showSubscribedDetail(plan)"
            >
              <span>{{ $t("staking.view_detail") }}</span>
            </Button>
          </template>
          <template v-else>
            <template slot="title">
              <div
                  class="d-flex flex-direction-column justify-center align-center pt-1 pb-1"
              >
                <img
                    :src="plan.coinDto.logoUrl"
                    alt=""
                    class="mb-1"
                    width="48"
                    height="48"
                />
                <p class="font-Regular-18 color-white line-height-24">
                  {{ plan.coinDto.displayName }}
                  <span
                      class="text-start font-Regular-12 color-gray line-height-20"
                  >({{ plan.coinDto.coinUnit }})</span
                  >
                </p>

                <p
                    class="text-center font-Regular-12 line-height-20 mt-3"
                    style="color: #a2aebf"
                >
                  {{ plan.description }}
                </p>
              </div>
            </template>
            <div class="mt-2 duration-list d-flex justify-center" :wrap="false">
              <div v-for="(duration, index) in plan.durations" :key="index">
                <div
                    class="mb-2 duration-item d-flex justify-center align-center"
                    :class="
                    getActiveDuration(
                      plan.stakingPlanId,
                      duration.planDetailId
                    ) && 'active'
                  "
                    @click="
                    setActiveDuration(plan.stakingPlanId, duration.planDetailId)
                  "
                >
                  <Icon
                      size="18"
                      type="ios-trending-up"
                      v-if="duration.apyType === 0"
                  />
                  <span class="text-start font-Regular-14 color-gray">{{
                      duration.duration
                    }}</span>
                  <span
                      v-if="
                      getActiveDuration(
                        plan.stakingPlanId,
                        duration.planDetailId
                      )
                    "
                      class="text-start font-Regular-14 color-gray"
                  >{{ $t("staking.day") }}</span
                  >
                </div>
              </div>
            </div>
            <div
                v-if="
                loadingDetail.planId === plan.stakingPlanId &&
                loadingDetail.status
              "
                class="h-273 d-flex justify-center"
            >
              <Loader/>
            </div>
            <div v-else>
              <Row
                  justify="space-between"
                  align="middle"
                  class="mt-6"
                  :class="isLocaleLTR ? 'flex-row-reverse' : 'flex-row'"
                  :wrap="getDetail(plan.stakingPlanId).apyType === 1"
              >
                <template v-if="getDetail(plan.stakingPlanId).apyType === 1">
                  <Col :span="24">
                    <p
                        class="ltr font-Bold-24 text-center color-green line-height-24"
                    >
                      {{ getDetail(plan.stakingPlanId).activeAPY }}%
                    </p>
                  </Col>
                  <Col :span="24">
                    <p class="text-center font-Regular-12 color-gray">
                      {{ $t("staking.fixed_apr") }}
                    </p>
                  </Col>
                </template>
                <template v-else>
                  <Col :span="6">
                    <p class="ltr font-Medium-16 color-gray">
                      {{ getDetail(plan.stakingPlanId).maxApy }}%
                    </p>
                    <p class="text-center font-Regular-12 color-gray">
                      {{ $t("staking.max_apr") }}
                    </p>
                  </Col>
                  <Col :span="3">
                    <div class="right-linear-divider"></div>
                  </Col>
                  <Col :span="6">
                    <p class="text-center font-Medium-16 color-green">
                      {{ getDetail(plan.stakingPlanId).activeAPY }}%≈
                    </p>
                    <p class="text-center font-Regular-12 color-gray">
                      {{ $t("staking.active_apr") }}
                    </p>
                  </Col>
                  <Col :span="3">
                    <div class="left-linear-divider"></div>
                  </Col>
                  <Col :span="6">
                    <p class="color-gray ltr font-Medium-16">
                      {{ getDetail(plan.stakingPlanId).minApy }}%
                    </p>
                    <p class="text-center font-Regular-12 color-gray">
                      {{ $t("staking.min_apr") }}
                    </p>
                  </Col>
                </template>
              </Row>
              <Row
                  justify="space-between"
                  align="middle"
                  class="mt-6"
              >
                <Col>
                  <div>
                    <p class="font-Regular-12 text-start color-dark-gray">
                      {{ $t("staking.registration_time") }}
                    </p>
                    <p
                        class="ltr color-white text-start font-Medium-14 pt-2 w-100"
                    >
                      {{
                        dateFormat(
                            getDetail(plan.stakingPlanId).endDate,
                            false
                        )
                      }}
                    </p>
                  </div>
                </Col>
                <Col>
                  <div>
                    <p class="font-Regular-12 text-start color-dark-gray">
                      {{ $t("staking.min_stake_amount") }}
                    </p>
                    <p
                        class="ltr color-white text-end font-Regular-14 pt-2 w-100"
                    >
                      {{ getDetail(plan.stakingPlanId).minStakePerUser }}
                      {{ plan.coinDto.coinUnit }}
                    </p>
                  </div>
                </Col>

              </Row>
              <Row class="mt-2">
                <Col :span="24">
                  <Slider
                      class="ltr"
                      :value="
                      getRemainCapacity(
                        plan.stakingPlanId,
                        getDetail(plan.stakingPlanId)
                      )
                    "
                      disabled
                  ></Slider>
                </Col>
                <Col :span="24" class="d-flex justify-end">
                  <p class="ltr font-Medium-12 color-smoke d-flex">
                    <span class="truncate-text">{{
                        numberWithCommas(
                            parseFloat((getDetail(plan.stakingPlanId).maxCapacity -
                                getDetail(plan.stakingPlanId).remainCapacity).toFixed(10))
                        )
                      }}</span>
                    /
                    <span class="d-inline-block">
                      {{
                        numberWithCommas(
                            getDetail(plan.stakingPlanId).maxCapacity
                        )
                      }}
                    </span>
                    <span class="font-Medium-12 ps-1">{{
                        plan.coinDto.coinUnit
                      }}</span>
                    <span class="font-Medium-12 color-orange ps-2"
                    >{{
                        Math.floor(
                            getRemainCapacity(
                                plan.stakingPlanId,
                                getDetail(plan.stakingPlanId)
                            )
                        )
                      }}%</span
                    >
                  </p>
                </Col>
              </Row>
              <Button
                  :class="[
                  'mt-4',
                  'mb-2',
                  'detail-btn',
                  (getDetail(plan.stakingPlanId).isRegisterEnded ||
                    getDetail(plan.stakingPlanId).isRegisterNotStarted) &&
                    'disabled',
                ]"
                  @click="showDetail(plan)"
                  :disabled="
                  (getDetail(plan.stakingPlanId).isRegisterEnded ||
                    getDetail(plan.stakingPlanId).isRegisterNotStarted)
                "
              >
                <span>{{ getPlanStatus(getDetail(plan.stakingPlanId)) }}</span>
              </Button>
            </div>
          </template>
        </Card>
      </Col>
    </Row>
    <div class="d-flex justify-center" v-if="totalPages > 1">
      <Button
          class="mt-8 mb-8 load-more-btn"
          :loading="loadingMore"
          @click="loadMore"
      >
        <span>{{ $t("staking.show_more") }}</span>
      </Button>
    </div>
  </div>
</template>
<script>
import Loader from "@/components/UI/LoadingComp";
import dayjs from "../../../plugins/dayjs";

export default {
  props: [
    "plansData",
    "totalPages",
    "loadingDetail",
    "loadingMore",
    "listType",
  ],
  components: {
    Loader,
  },
  methods: {
    dayjs,
    dateFormat: function (tick, option) {
      return this.$options.filters.dateFormat(tick, option);
    },
    showDetail(plan) {
      this.$router.push({
        query: {
          plan: plan.stakingPlanId
        }
      })
      this.$emit("stakeModal", {
        type: "enter",
        planId: plan.stakingPlanId,
        durationId: plan.details[0].id,
      });
    },
    showSubscribedDetail(plan) {
      this.$emit("stakeModal", {
        type: "view",
        planId: plan.subscriptionId,
        durationId: plan.stakingPlanDetailId,
      });
    },
    loadMore() {
      this.$emit("loadMore");
    },
    getRemainCapacity(planId, planDetails) {
      const index = this.plansData.findIndex(
          (obj) => obj.stakingPlanId === planId
      );
      if (this.plansData[index].details[0].id === planDetails.id) {
        return (
            (100 * (planDetails.maxCapacity - planDetails.remainCapacity)) /
            planDetails.maxCapacity
        );
      }
    },
    getActiveDuration(planId, durationId) {
      return (
          this.plansData.findIndex(
              (obj) =>
                  obj.stakingPlanId === planId && obj.details[0].id === durationId
          ) > -1
      );
    },
    setActiveDuration(planId, durationId) {
      this.$emit("updateDetailByDuration", {planId, durationId});
    },
    getDetail(planId) {
      return this.plansData.find((obj) => obj.stakingPlanId === planId)
          .details[0];
    },
    getPlanStatus(planStatus) {
      let status = "";
      planStatus.isRegisterNotStarted
          ? (status = this.$t("staking.registration_not_started"))
          : planStatus.remainCapacity === 0
              ? (status = this.$t("staking.filled_capacity"))
              : planStatus.isRegisterEnded
                  ? (status = this.$t("staking.registration_expired"))
                  : (status = this.$t("staking.enter_plan"));
      return status;
    },
    getSubscriptionStatus(status) {
      if (status === "ACTIVE") {
        return {className: "Success", text: this.$t("staking.status_active")};
      } else if (status === "TERMINATED") {
        return {
          className: "Terminate",
          text: this.$t("staking.status_terminated"),
        };
      } else if (status === "CANCELED") {
        return {className: "Error", text: this.$t("staking.status_cancel")};
      }
    },
    getRemainedDate(endTime) {
      const currentTime = new Date().getTime();
      return {
        day:
            Math.floor((endTime - currentTime) / (1000 * 60 * 60 * 24)) > 0
                ? Math.floor((endTime - currentTime) / (1000 * 60 * 60 * 24))
                : 0,
        hour:
            Math.floor(
                ((endTime - currentTime) % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            ) > 0
                ? Math.floor(
                    ((endTime - currentTime) % (1000 * 60 * 60 * 24)) /
                    (1000 * 60 * 60)
                )
                : 0,
        minute:
            Math.floor(
                ((endTime - currentTime) % (1000 * 60 * 60)) / (1000 * 60)
            ) > 0
                ? Math.floor(
                    ((endTime - currentTime) % (1000 * 60 * 60)) / (1000 * 60)
                )
                : 0,
      };
    },
  },
  mounted() {
    const isQuery = this.$route.query.plan
    if (isQuery) {
      const plan = this.plansData.find(item => item.stakingPlanId == isQuery)
      this.$emit("stakeModal", {
        type: "enter",
        planId: +isQuery,
        durationId: +plan.details[0].id,
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.stake-card {
  &.public-card {
    border-radius: 8px;
    border: 1px solid #394350;
    background: #172232;

    &:hover {
      border-radius: 8px;
      border: 1px solid #747b84;
      background: #172232;
    }

    .duration-list {
      gap: 4%;
      overflow-x: auto;

      &::-webkit-scrollbar {
        height: 0;
      }

      .duration-item {
        padding: 2px 4px;
        border-radius: 4px;
        border: 1px solid #a2aebf;

        &.active {
          border: 1px solid #f1ac1c;
          background: #2b3b4f;

          span {
            color: #fff;
          }
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    .right-linear-divider {
      // width: 45fr;
      height: 2px;
      background: linear-gradient(270deg, #d69000 1.25%, #5a4b28 100%);
      border-radius: 70px;
    }

    .left-linear-divider {
      // width: 45px;
      height: 2px;
      background: linear-gradient(
              270deg,
              rgba(241, 172, 28, 0.32) 0%,
              rgba(217, 217, 217, 0) 100%
      );
      border-radius: 70px;
    }

    .truncate-text {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 60px;
    }
  }

  &.registered-card {
    .plan-status {
      border-radius: 4px;
      padding: 6px 12px;

      &.Success {
        background: rgba(2, 192, 118, 0.16);

        > span {
          color: #02c076;
        }
      }

      &.Info {
        background: rgba(46, 157, 255, 0.16);

        > span {
          color: #2e9dff;
        }
      }

      &.Warning {
        background: rgba(241, 143, 1, 0.16);

        > span {
          color: #f18f01;
        }
      }

      &.Error {
        background: rgba(248, 73, 96, 0.16);

        > span {
          color: #f84960;
        }
      }

      &.Terminate {
        background: rgba(255, 255, 255, 0.2);

        > span {
          color: #a2aebf;
        }
      }

      &.Disabled {
        background: #2b3b4f;

        > span {
          color: #ffffff;
        }
      }
    }

    .expired-plan {
      opacity: 24%;
    }
  }
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-row {
  flex-direction: row;
}
</style>
