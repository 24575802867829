var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Row',{staticClass:"ps-7 pt-1 modal-header",attrs:{"type":"flex"}},[_c('Col',{staticClass:"d-flex justify-start align-center mb-4",attrs:{"lg":6,"sm":12,"span":24}},[_c('img',{attrs:{"src":_vm.content.coinDto.logoUrl,"width":"32","height":"32"}}),_c('div',{staticClass:"ms-2"},[_c('p',{staticClass:"color-white font-Regular-18 text-start line-height-24"},[_vm._v(" "+_vm._s(_vm.content.coinDto.displayName)+" ")]),_c('p',{staticClass:"color-gray text-start font-Regular-12 text-start line-height-20"},[_vm._v(" "+_vm._s(_vm.content.description)+" ")])])]),(_vm.content.durations)?_c('Col',{staticClass:"duration-list mb-4",attrs:{"lg":{ span: 12, order: 2 },"span":24,"order":3}},[_c('p',{staticClass:"color-gray font-Regular-14 me-6"},[_vm._v(" "+_vm._s(_vm.$t("staking.plan_duration"))+" ")]),_c('div',{class:[
        'duration-list',
        'd-flex',
        'gap-4',
        _vm.content.durations.length > 4 && 'responsive-duration-container',
      ]},_vm._l((_vm.content.durations),function(duration,index){return _c('div',{key:index},[_c('div',{staticClass:"d-flex justify-center align-center duration-item",class:duration.planDetailId === _vm.content.activeDuration && 'active',on:{"click":function($event){return _vm.setActiveDuration(duration.planDetailId)}}},[(duration.apyType === 0)?_c('Icon',{attrs:{"size":"18","type":"ios-trending-up"}}):_vm._e(),_c('span',{staticClass:"text-start font-Regular-14 color-gray"},[_vm._v(_vm._s(duration.duration))]),(duration.planDetailId === _vm.content.activeDuration)?_c('span',{staticClass:"text-start font-Regular-14 color-gray"},[_vm._v(_vm._s(_vm.$t("staking.day")))]):_vm._e()],1)])}),0)]):_vm._e(),_c('Col',{staticClass:"d-flex align-center mb-4",attrs:{"lg":{ span: 6, order: 3 },"sm":12,"span":24,"order":2}},[_c('p',{staticClass:"color-gray text-center font-Regular-14 me-6"},[_vm._v(" "+_vm._s(_vm.$t("staking.plan_status"))+" ")]),(_vm.content.status)?_c('div',{class:[_vm.getStatus(_vm.content.status).className, 'plan-status']},[_c('span',{staticClass:"font-Regular-12"},[_vm._v(_vm._s(_vm.getStatus(_vm.content.status).text))])]):_c('div',{class:[
        'plan-status',
        _vm.content.details[0].isRegisterEnded ||
        _vm.content.details[0].isRegisterNotStarted ||
        _vm.content.details[0].remainCapacity === 0
          ? 'Error'
          : 'Success',
      ]},[_c('span',{staticClass:"font-Regular-12"},[_vm._v(_vm._s(_vm.content.details[0].isRegisterEnded || _vm.content.details[0].isRegisterNotStarted || _vm.content.details[0].remainCapacity === 0 ? _vm.$t("staking.not_exist") : _vm.$t("staking.exist")))])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }