<template>
  <Row :gutter="32" class="mt-6" v-if="screenWidth >= 992">
    <Col :lg="8" :span="24">
      <div class="staking-card">
        <div class="pt-7 pb-7 ps-6 pe-6">
          <div class="d-flex align-center">
            <img :src="boxIcon" alt="gift" />
            <p class="text-start font-Regular-20 ms-2  color-orange">
              {{ $t("staking.apr_reward") }}
            </p>
          </div>
          <div class="mt-4">
            <p class="text-start font-Regular-14 line-height-30 color-smoke">
              {{ $t("staking.apr_reward_text") }}
            </p>
          </div>
        </div>
      </div>
    </Col>
    <Col :lg="8" :span="24">
      <div class="staking-card">
        <div class="pt-7 pb-7 ps-6 pe-6">
          <div class="d-flex align-center">
            <img :src="checkMarkIcon" alt="fixed" />
            <p class="text-start font-Regular-20 ms-2  color-orange">
              {{ $t("staking.fixed_stake") }}
            </p>
          </div>
          <div class="mt-4">
            <p class="text-start font-Regular-14 line-height-30 color-smoke">
              {{ $t("staking.fixed_stake_text") }}
            </p>
          </div>
        </div>
      </div>
    </Col>
    <Col :lg="8" :span="24">
      <div class="staking-card">
        <div class="pt-7 pb-7 ps-6 pe-6">
          <div class="d-flex align-center">
            <img :src="refreshIcon" alt="flex" />
            <p class="text-start font-Regular-20 ms-2  color-orange">
              {{ $t("staking.flexible_stake") }}
            </p>
          </div>
          <div class="mt-4">
            <p class="text-start font-Regular-14 line-height-30 color-smoke">
              {{ $t("staking.flexible_stake_text") }}
            </p>
          </div>
        </div>
      </div>
    </Col>
  </Row>
  <div v-else class="pt-4">
    <Collapse class="staking-collapse" @on-change="collapseTrigger" accordion>
      <Panel hide-arrow class="collapse-panel mb-4">
        <div class="d-flex flex-row-reverse justify-between align-center">
          <Icon
            color="#F1AC1C"
            size="18"
            :type="collapseState === '0' ? 'ios-arrow-up' : 'ios-arrow-down'"
          />
          <div class="d-flex align-center">
            <img :src="boxIcon" alt="icon" width="24" height="24" />
            <p class="text-start font-Regular-16 ms-3  color-orange">
              {{ $t("staking.apr_reward") }}
            </p>
          </div>
        </div>
        <template slot="content">
          <div class="mt-4">
            <p class="text-start font-Regular-14 line-height-30 color-smoke">
              {{ $t("staking.apr_reward_text") }}
            </p>
          </div>
        </template>
      </Panel>
      <Panel hide-arrow class="collapse-panel mb-4">
        <div class="d-flex flex-row-reverse justify-between align-center">
          <Icon
            color="#F1AC1C"
            size="18"
            :type="collapseState === '1' ? 'ios-arrow-up' : 'ios-arrow-down'"
          />
          <div class="d-flex align-center">
            <img :src="checkMarkIcon" alt="icon" width="24" height="24" />
            <p class="text-start font-Regular-16 ms-3  color-orange">
              {{ $t("staking.fixed_stake") }}
            </p>
          </div>
        </div>
        <template slot="content">
          <div class="mt-4">
            <p class="text-start font-Regular-14 line-height-30 color-smoke">
              {{ $t("staking.fixed_stake_text") }}
            </p>
          </div>
        </template>
      </Panel>
      <Panel hide-arrow class="collapse-panel mb-4">
        <div class="d-flex flex-row-reverse justify-between align-center">
          <Icon
            color="#F1AC1C"
            size="18"
            :type="collapseState === '2' ? 'ios-arrow-up' : 'ios-arrow-down'"
          />
          <div class="d-flex align-center">
            <img :src="refreshIcon" alt="icon" width="24" height="24" />
            <p class="text-start font-Regular-16 ms-3  color-orange">
              {{ $t("staking.flexible_stake") }}
            </p>
          </div>
        </div>
        <template slot="content">
          <div class="mt-4">
            <p class="text-start font-Regular-14 line-height-30 color-smoke">
              {{ $t("staking.flexible_stake_text") }}
            </p>
          </div>
        </template>
      </Panel>
    </Collapse>
  </div>
</template>

<script>
import Box from "@/assets/images/Box.svg";
import StarCheckmark from "@/assets/images/StarCheckmark.svg";
import flexIcon from "@/assets/images/flexIcon.svg";
export default {
  data() {
    return {
      boxIcon: Box,
      checkMarkIcon: StarCheckmark,
      refreshIcon: flexIcon,
      collapseState: null,
    };
  },
  computed: {
    screenWidth() {
      return window.innerWidth;
    },
  },
  methods: {
    collapseTrigger(e) {
      this.collapseState = e[0];
    },
  },
};
</script>

<style lang="scss" scoped>
.line-height-30 {
  line-height: 30px;
}
.staking-card {
  background: rgba(23, 34, 50, 0.33);
  border-radius: 16px;
  border-top: 0.5px rgba(241, 172, 28, 0.68) solid;
  height: 100% !important;
}
.staking-collapse {
  border: none;
  background: none;
  border-radius: none;
  .collapse-panel {
    background: rgba(23, 34, 50, 0.33);
    border-radius: 16px;
    border-top: 0.5px rgba(241, 172, 28, 0.68) solid !important;
    height: 100%;
    padding: 16px;
  }
}
</style>
